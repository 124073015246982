export default [
    {
        title: "Dashboard",
        route: "dashboard-profesional",
        icon: "BarChart2Icon",
    },
    {
        title: "Mis pacientes",
        route: "mis-pacientes",
        icon: "UserIcon",
    },
    {
        title: "Asistencias",
        route: "asistencias",
        icon: "ActivityIcon",
    },
    {
        title: "Mi agenda",
        route: "agenda",
        icon: "BookIcon",
    },
];
