export default [
    {
        title: "Dashboard",
        route: "dashboard-admin",
        icon: "BarChart2Icon",
    },
    {
        title: "Entidades",
        route: "entidades",
        icon: "HomeIcon",
    },
    {
        title: "Profesionales",
        route: "profesionales",
        icon: "UsersIcon",
    },
    {
        title: "Pacientes",
        route: "pacientes",
        icon: "HeartIcon",
    },
    {
        title: "Soportes",
        route: "soportes",
        icon: "HelpCircleIcon",
    },
];
