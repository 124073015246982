export default [
    {
        title: "Dashboard",
        route: "dashboard-profesional",
        icon: "BarChart2Icon",
    },
    {
        title: "Profesionales",
        route: "pacientes",
        icon: "UserIcon",
    },
    {
        title: "Entidades",
        route: "asistencias",
        icon: "ActivityIcon",
    },
    {
        title: "Entidades",
        route: "asistencias",
        icon: "ActivityIcon",
    },
];
