<template>
    <div class="navbar-container d-flex content align-items-center">
        <!-- Nav Menu Toggler -->
        <ul class="nav navbar-nav d-xl-none">
            <li class="nav-item">
                <b-link class="nav-link" @click="toggleVerticalMenuActive">
                    <feather-icon icon="MenuIcon" size="21" />
                </b-link>
            </li>
        </ul>

        <!-- Left Col -->
        <div
            class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
        >
            <dark-Toggler class="d-none d-lg-block" />
        </div>
        <b-navbar-nav class="nav align-items-center ml-auto">
            <b-nav-item-dropdown
                right
                toggle-class="d-flex align-items-center dropdown-user-link"
                class="dropdown-user"
            >
                <template #button-content>
                    <div class="d-sm-flex d-none user-nav">
                        <p class="user-name font-weight-bolder mb-0">
                            {{ user.nombre }}
                            {{ user.apellido }}
                        </p>
                        <span v-show="user.tipo === 1" class="user-status"
                            >Super Admin</span
                        >
                        <span v-show="user.tipo === 2" class="user-status"
                            >Admin</span
                        >
                        <span v-show="user.tipo === 4" class="user-status"
                            >Profesional</span
                        >
                    </div>
                    <b-avatar
                        size="40"
                        variant="light-primary"
                        badge
                        class="badge-minimal"
                        badge-variant="success"
                    />
                </template>
                <b-dropdown-item
                    link-class="d-flex align-items-center"
                    to="/profile"
                >
                    <feather-icon size="16" icon="UserIcon" class="mr-50" />
                    <span>Perfil</span>
                </b-dropdown-item>

                <b-dropdown-divider />

                <b-dropdown-item
                    to="/login"
                    link-class="d-flex align-items-center"
                >
                    <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
                    <span>Cerrar sesión</span>
                </b-dropdown-item>
            </b-nav-item-dropdown>
        </b-navbar-nav>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";
import { ref, onMounted } from "@vue/composition-api";
export default {
    components: {
        // Navbar Components
        DarkToggler,
    },
    props: {
        toggleVerticalMenuActive: {
            type: Function,
            default: () => {},
        },
    },
    computed: {
        ...mapGetters({
            user: "auth/user",
        }),
    },
};
</script>
